import React, {useEffect, useRef} from 'react';
import {
    Grid,
    TextField,
    Button,
    Card,
    CardContent,
    FormControlLabel,
    Switch, Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import TwilioService from '../services/TwilioService';
import LocalStorageService from "../config/LocalStorageService";

const {Device} = require('twilio-client');

export default function VoipComponent() {
    const service = new TwilioService();
    const device = useRef(null);
    const beToken = "BE-token";
    const apiSecret = 'FE-secret';
    const targetClient = "target-client";
    const targetStand = "target-stand";
    const [from, setFrom] = React.useState('+16693008310');
    const [to, setTo] = React.useState({
        checked: true,
        display: 'Android',
        devNumber: '+97233727095',
        stageNumber: '+97293762491',
    });
    const [stand, setStand] = React.useState({
        checked: true,
        display: 'Develop',
        path: 'dev',
    });
    const [token, setToken] = React.useState("");
    const [secretKey, setSecretKey] = React.useState("");
    const [connected, setConnected] = React.useState(false);
    const switchChecked = () => {
        let value = {};
        if (to.checked) {
            value = {
                checked: false,
                display: 'iOS',
                stageNumber: '+97233769270',
                devNumber: '+972765997217',
            };
        } else {
            value = {
                checked: true,
                display: 'Android',
                stageNumber: '+97293762491',
                devNumber: '+97233727095',
            }
        }
        setTo(value);
        LocalStorageService.set(targetClient, value);
    };

    const switchStand = () => {
        let value = {};
        if (stand.checked) {
            value = {
                checked: false,
                display: 'Staging',
                path: 'stage',
            };
        } else {
            value = {
                checked: true,
                display: 'Develop',
                path: 'dev',
            };
        }
        setTo(value);
        LocalStorageService.set(targetStand, value);
    };
    const [status, setStatus] = React.useState(0);
    const [statusText, setStatusText] = React.useState('Not connected');
    const levels = ['info', 'success', 'error'];
    useEffect(() => {
        setToken(LocalStorageService.get(beToken));
        setSecretKey(LocalStorageService.get(apiSecret));
        if (LocalStorageService.get(targetClient) !== "") {
            setTo(LocalStorageService.get(targetClient))
        }
        if (LocalStorageService.get(targetStand) !== "") {
            setStand(LocalStorageService.get(targetStand))
        }
    }, []);

    async function sendRequest() {
        service.sendRequest(stand.path, token, secretKey, Math.floor(new Date().getTime() / 1000.0), {
            from: "+972587202808",
            to: "+16693008314",
            description: "Random description",
            callerName: "Random callerName"
        })
    }

    async function connect() {
        let data = null;
        try {
            data = await service.getToken(stand.path, token, secretKey, Math.floor(new Date().getTime() / 1000.0));
            setFrom(data.fromNumber);
            device.current = new Device(data.token, {
                codecPreferences: ['opus', 'pcmu'],
                fakeLocalDTMF: true,
                enableRingingState: true,
                debug: true,
            });
            device.current.on('ready', function () {
                setStatus(1);
                setStatusText('Connected');
                LocalStorageService.set(beToken, token);
                LocalStorageService.set(apiSecret, secretKey);
                LocalStorageService.set(targetStand, stand);
                LocalStorageService.set(targetClient, to);
                setConnected(true);
            });

            device.current.on('disconnect', function () {
                setStatusText('Call ended');
            });
            device.current.on('error', function (error) {
                setStatus(3);
                setStatusText('Error: ' + error.message);
                console.log('Twilio.Device Error: ' + error.message);
                setConnected(false);
            });
        } catch (e) {
            setStatusText('Error: ' + e.message);
            console.log('Twilio.Device Error: ' + e.message);
        }
    }

    function disconnect() {
        device.current.disconnectAll();
        setStatus(1);
    }

    const call = () => {
        const outboundNumber = stand.checked ? to.devNumber : to.stageNumber;
        let outgoingConnection = device.current.connect({
            Caller: from,
            To: outboundNumber,
        });
        outgoingConnection.on('ringing', function () {
            setStatus(2);
            setStatusText(`Calling ${outboundNumber}...`);
        });
        outgoingConnection.on('reject', function () {
            console.error('Call rejected!');
        });
        outgoingConnection.on('disconnect', function (con) {
            console.log(con);
            console.error(outgoingConnection);
        });
    };

    function getOnTokenChange(e) {
        setToken(e.target.value);
    }

    function getOnSecretKeyChange(e) {
        setSecretKey(e.target.value);
    }

    return (
        <Card>
            <CardContent>
                <Grid container direction='row' alignItems='center' spacing={2}>
                    <Grid item xs={12} hidden={connected}>
                        <TextField label="access_key_id" name="access_key_id" variant="outlined" size="small" fullWidth
                                   value={token}
                                   type={"password"}
                                   onChange={getOnTokenChange}/>
                    </Grid>
                    <Grid item xs={12} hidden={connected}>
                        <TextField label="secret_access_key" name="secret_access_key" variant="outlined" size="small"
                                   fullWidth
                                   value={secretKey}
                                   type={"password"}
                                   onChange={getOnSecretKeyChange}/>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant='overline'>Stand:</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControlLabel
                            control={<Switch checked={stand.checked} color='secondary'
                                             disabled={status === 1 || status === 2}/>}
                            name='to'
                            value={stand.checked}
                            label={stand.display}
                            onClick={switchStand}
                            size='large'
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Button
                            size='large'
                            color={stand.checked ? 'primary' : 'secondary'}
                            variant='contained'
                            onClick={connect}
                            fullWidth
                            disabled={status !== 0 || token === undefined || token.length === 0}
                        >
                            {status !== 0 ? 'connected' : 'Connect'}
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant='overline'>Client:</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControlLabel
                            control={<Switch checked={to.checked} color='primary'
                                             disabled={status === 1 || status === 2}/>}
                            name='to'
                            value={to.checked}
                            label={to.display}
                            onClick={switchChecked}
                            size='large'
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Button
                            size='large'
                            fullWidth
                            variant='contained'
                            color={status === 2 ? 'secondary' : 'primary'}
                            disabled={status === 0}
                            onClick={() => {
                                if (status === 2) disconnect();
                                else call();
                            }}
                        >
                            {status === 2 ? 'Hang' : 'Call'}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Alert severity={levels[status]}>{statusText}</Alert>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            size='large'
                            fullWidth
                            variant='contained'
                            color='primary'
                            onClick={sendRequest}
                        >
                            REQUEST
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
