import React from 'react';
import {Grid, Container, Typography} from '@material-ui/core';

import VoipComponent from './components/VoipComponent';

function App() {
    return (
        <Container fixed>
            <Grid container>
                <Container maxWidth="xs" fixed>
                    <Typography variant='h4' align="center">Lifelee test api</Typography>
                </Container>
            </Grid>
            <Grid>
                <Container maxWidth="xs" fixed>
                    <VoipComponent/>
                </Container>
            </Grid>
        </Container>
    );
}

export default App;
