import axios from 'axios';
import {ENDPOINT_URL} from '../config/constants';
import createHmac from 'create-hmac';

export default class TwilioService {
    async sendRequest(stand, token, secretKey, timeStamp, body) {
        const stringBody= JSON.stringify(body);
        const hmac = createHmac('sha512', Buffer.from(secretKey))
            .update(timeStamp + '.' + stringBody, 'utf8').digest("hex");
        let response;
        try {
            response = await axios.post(`https://${stand}.${ENDPOINT_URL}/api/v1/partner/expected_call`, body, {
                headers: {
                    Authorization: `api-key ${token}`,
                    signature: `${timeStamp};${hmac}`,
                    "Content-Type": 'application/json'
                }
            });
        } catch (e) {
            throw e;
        }
    }

    async getToken(stand, token, secretKey, timeStamp) {
        const hmac = createHmac('sha512', Buffer.from(secretKey))
            .update(timeStamp + '.', 'utf8').digest("hex");
        let response;
        try {
            response = await axios.get(`https://${stand}.${ENDPOINT_URL}/mobile/user/test_token`, {
                headers: {
                    Authorization: `api-key ${token}`,
                    signature: `${timeStamp};${hmac}`
                },
            });
        } catch (e) {
            throw e;
        }
        return response.data;
    }
}
